import LoginPage from "./pages/auth/login";
import DashboardPage from "./pages/admin/dashboard.page";
import ForgotPasswordPage from "./pages/auth/forgot-password";
import ResetPasswordPage from "./pages/auth/reset-password";

import { NAV_ICONS } from "./assets/images";

const FORGOT_PASSWORD = "forgot-password";

export const AuthNavigation = [
  {
    path: "login",
    layout: "auth",
    name: "login",
    page: () => LoginPage,
  },
  {
    path: FORGOT_PASSWORD,
    layout: "auth",
    name: FORGOT_PASSWORD,
    page: () => ForgotPasswordPage,
  },
  {
    path: "reset-password",
    layout: "auth",
    name: FORGOT_PASSWORD,
    page: () => ResetPasswordPage,
  },
];

export const AdminNavigation = [
  {
    path: "dashboard",
    layout: "admin",
    name: "Dashboard",
    icon: NAV_ICONS.dashboardInActiveIcon,
    iconActive: NAV_ICONS.dashboardActiveIcon,
    page: () => DashboardPage,
  },
  {
    path: "classes",
    layout: "admin",
    name: "Experiences",
    icon: NAV_ICONS.classInActiveIcon,
    iconActive: NAV_ICONS.classActiveIcon,
  },
  {
    path: "explorers-listing",
    layout: "admin",
    name: "Explorer",
    icon: NAV_ICONS.explorerInActiveIcon,
    iconActive: NAV_ICONS.explorerActiveIcon,
  },
  {
    path: "guides-listing",
    layout: "admin",
    name: "Guide",
    icon: NAV_ICONS.guideInActiveIcon,
    iconActive: NAV_ICONS.guideActiveIcon,
  },
  {
    path: "giftcard-listing",
    layout: "admin",
    name: "Gift Cards",
    icon: NAV_ICONS.emailInActiveIcon,
    iconActive: NAV_ICONS.emailActiveIcon,
  },
  {
    path: "broadcast-message-listing",
    layout: "admin",
    name: "Broadcast Message",
    icon: NAV_ICONS.broadcastInActiveIcon,
    iconActive: NAV_ICONS.broadcastActiveIcon,
  },
  {
    path: "email-templates",
    layout: "admin",
    name: "Email Template",
    icon: NAV_ICONS.emailInActiveIcon,
    iconActive: NAV_ICONS.emailActiveIcon,
  },
  {
    path: "promoted-classes",
    layout: "admin",
    name: "Promoted Class",
    icon: NAV_ICONS.promotedClassInActiveIcon,
    iconActive: NAV_ICONS.promotedClassActiveIcon,
  },
  {
    path: "coupons-listing",
    layout: "admin",
    name: "Coupons",
    icon: NAV_ICONS.couponInActiveIcon,
    iconActive: NAV_ICONS.couponActiveIcon,
  },
  {
    path: "admins-listing",
    layout: "admin",
    name: "Admin Settings",
    icon: NAV_ICONS.adminSettingInActiveIcon,
    iconActive: NAV_ICONS.adminSettingActiveIcon,
  },
];
