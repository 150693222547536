/**
 *
 * @returns string
 * getting app bar text based on path name
 */

export const getTopAppbarText = () => {
  switch (window.location?.pathname) {
    case "/admin/dashboard":
      return "Dashboard";
    case "/admin/giftcard-listing":
      return "Gift Cards"
    case "/admin/classes":
    case "/admin/class-details":
    case "/admin/edit-class":
      return "Experiences";
    case "/admin/explorers-listing":
    case "/admin/edit-explorer":
    case "/admin/explorer-details":
      return "Explorer";
    case "/admin/guides-listing":
    case "/admin/view-guide":
    case "/admin/edit-guide":
      return "Guide";
    case "/admin/broadcast-message-listing":
    case "/admin/view-broadcast-message-details":
    case "/admin/new-broadcast-message":
      return "Broadcast Message";
    case "/admin/email-templates":
    case "/admin/edit-email-template":
      return "Email Template";
    case "/admin/communication-chats-listing":
      return "Communication Chat";
    case "/admin/promoted-classes":
      return "Promoted Class";
    case "/admin/coupons-listing":
    case "/admin/create-coupon":
    case "/admin/edit-coupon":
    case "/admin/view-coupon-details":
      return "Coupons";
    case "/admin/admins-listing":
    case "/admin/add-new-admin":
    case "/admin/edit-admin":
    case "/admin/change-password":
      return "Admin Settings";
    default:
      return "";
  }
};
