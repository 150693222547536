import { errorConst } from "../../../text-constants";
import { fetchRedemtionController, fetchPurchasedController } from "../../../../controllers/giftcardController";
import { fetchExplorersController } from "../../../../controllers/explorersController";

// Function to fetch the redemption list
export const getRedemtionList = (
  setState: any,
  state: any,
  setMsg: any,
  params?: any,
  userType?: string
) => {
  setState((prev: any) => ({
    ...prev,
    isLoading: true,
  }));

  const queryParams = {
    skip: state.page + 1,
    limit: state.pageSize,
    ...params,
  };
  fetchRedemtionController(
    (response: any) => successFetchRedemtion(response, setState, state),
    (error: any) => errorFetch(error, setState, setMsg),
    queryParams
  );
};

// Function to fetch the purchased list
export const getPurchasedList = (
  setState: any,
  state: any,
  setMsg: any,
  params?: any
) => {
  setState((prev: any) => ({
    ...prev,
    isLoading: true,
  }));

  const queryParams = {
    skip: state.page + 1,
    limit: state.pageSize,
    ...params,
  };

  fetchPurchasedController(
    (response: any) => successFetchPurchased(response, setState, state),
    (error: any) => errorFetch(error, setState, setMsg),
    queryParams
  );
};

// Success handler for redemption list
function successFetchRedemtion(response: any, setState: any, state: any) {
    console.log("Redemtion response =>", response);
  const data = response.data.redeemedGiftCards.map((item: any, i: number) => {
    const id = i + 1 + state.page * state.pageSize;
    return { ...item, id };
  });
  console.log("Redemtion data =>", data);

  setState((prev: any) => ({
    ...prev,
    isLoading: false,
    data: data,
    total: response.data.metadata.totalDocuments,
  }));
}

// Success handler for purchased list
function successFetchPurchased(response: any, setState: any, state: any) {
  console.log("Purchased response =>", response);

  const data = response.data.purchasedGifts.map((item: any, i: number) => {
    const id = i + 1 + state.page * state.pageSize;
    return { ...item, id };
  });
  console.log("Purchased data =>", data);

  setState((prev: any) => ({
    ...prev,
    isLoading: false,
    data:data,
    total: response.data.metadata.totalDocuments,
  }));
}

// Common error handler for both fetch operations
function errorFetch(error: any, setState: any, setMsg: any) {
  setState((prev: any) => ({
    ...prev,
    isLoading: false,
  }));
  setMsg({
    show: true,
    type: "error",
    msg: getErrorMsg(error),
    errorType: "default",
  });
}

// Utility function to get error message
function getErrorMsg(error: any) {
  return error?.data?.message ? error?.data?.message : errorConst.UNKNOW_ERROR;
}