import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const getAllClassData = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_ALL_CLASSES_DATA,
    successHandler,
    errorHandler,
    params
  );
};

export const getAllUsersData = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_ALL_USERS_DATA,
    successHandler,
    errorHandler,
    params
  );
};

export const getAllGuidesData = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_ALL_GUIDES_DATA,
    successHandler,
    errorHandler,
    params
  );
};

// Function to fetch all purchased gift cards
export const getAllPurchasedGiftCardsData = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_ALL_PURCHASED_GIFTCARDS_DATA,
    successHandler,
    errorHandler,
    params
  );
};

// Function to fetch all redeemed gift cards
export const getAllRedeemedGiftCardsData = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_ALL_REDEEMED_GIFTCARDS_DATA,
    successHandler,
    errorHandler,
    params
  );
};

export const getAllExplorerData = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_ALL_EXPLORER_DATA,
    successHandler,
    errorHandler,
    params
  );
};

export const getAllBookingsData = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.EXPORT_ALL_BOOKINGS_DATA,
    successHandler,
    errorHandler,
    params
  );
};
