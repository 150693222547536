import {
  getAllBookingsData,
  getAllClassData,
  getAllExplorerData,
  getAllGuidesData,
  getAllUsersData,
  getAllPurchasedGiftCardsData,
  getAllRedeemedGiftCardsData
} from "../../controllers/exportDataController";
import { SetStateAction } from "react";
import { getRequiredUserData, getRequiredPurchasedGiftCardData, getRequiredRedeemedGiftCardData } from "./exportUserDataFunction";
import { utils, writeFile } from "xlsx";
import { getRequiredClassData } from "./exportClassDataFunction";
import { getSelectedFilters } from "./filterFunctions";
import { getRequiredGuideData } from "./exportGuideDataFunction";
import { getRequiredExplorerData } from "./exportExplorerFunction";
import { getExportBookingsData } from "./exportBookingsDataFunction";

export const preCheckDownloadReport = (
  selectedFilters: any,
  searchText: string,
  value: number,
  setIsReportDownloading: any,
  extraFilters?: any
) => {
  let filterOptions: any = { ...extraFilters };
  if (selectedFilters) {
    filterOptions = getSelectedFilters(selectedFilters);
  }
  if (searchText?.toString()?.trim()) {
    filterOptions = {
      ...filterOptions,
      search: searchText,
    };
  }
  if (value === 0) {
    exportDataApiCalls("class", setIsReportDownloading, filterOptions);
  } else if (value === 1) {
    exportDataApiCalls("users", setIsReportDownloading, filterOptions);
  } else if (value === 2) {
    exportDataApiCalls("guides", setIsReportDownloading, filterOptions);
  } else if (value === 3) {
    exportDataApiCalls("explorer", setIsReportDownloading, filterOptions);
  }
};

export const exportDataApiCalls = (
  tabName: string,
  setState: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void;
  },
  params: any
) => {
  setState((prev) => !prev);
  switch (tabName) {
    case "class":
      getAllClassData(
        (data: any) => {
          setState((prev) => !prev);
          successApiCall(data.data, "class");
        },
        (data: any) => {
          setState((prev) => !prev);
          errorApiCall(data, "class");
        },
        params
      );
      return;

    case "users":
      getAllUsersData(
        (data: any) => {
          setState((prev) => !prev);
          successApiCall(data.data, "users");
        },
        (data: any) => {
          setState((prev) => !prev);
          errorApiCall(data, "users");
        },
        params
      );
      return;

    case "guides":
      getAllGuidesData(
        (data: any) => {
          setState((prev) => !prev);
          successApiCall(data.Guide, "guides");
        },
        (data: any) => {
          setState((prev) => !prev);
          errorApiCall(data, "guides");
        },
        params
      );
      return;

      // For purchased gift cards
    case "purchasedGiftCards":
      getAllPurchasedGiftCardsData(
        (data: any) => {
          setState((prev) => !prev);
          successApiCall(data.giftCards, "purchasedGiftCards");
        },
        (data: any) => {
          setState((prev) => !prev);
          errorApiCall(data, "purchasedGiftCards");
        },
        params
      );
      return;

    // For redeemed gift cards
    case "redeemedGiftCards":
      getAllRedeemedGiftCardsData(
        (data: any) => {
          setState((prev) => !prev);
          successApiCall(data.giftCards, "redeemedGiftCards");
        },
        (data: any) => {
          setState((prev) => !prev);
          errorApiCall(data, "redeemedGiftCards");
        },
        params
      );
      return;

    case "explorer":
      getAllExplorerData(
        (data: any) => {
          setState((prev) => !prev);
          successApiCall(data.Explorer, "explorer");
        },
        (data: any) => {
          setState((prev) => !prev);
          errorApiCall(data, "explorer");
        },
        params
      );
      return;

    case "bookings":
      getAllBookingsData(
        (data: any) => {
          setState((prev) => !prev);
          successApiCall(data.data, "bookings");
        },
        (data: any) => {
          setState((prev) => !prev);
          errorApiCall(data, "bookings");
        },
        params
      );
      return;

    default:
      return;
  }
};

const successApiCall = (data1: any, dataType: string) => {
  const fileName: string = getFileName(dataType);
  let requiredData: any[] = [];
  if (dataType === "class") {
    requiredData = getRequiredClassData(data1);
  } else if (dataType === "users") {
    requiredData = getRequiredUserData(data1);
  } else if (dataType === "guides") {
    requiredData = getRequiredGuideData(data1);
  } else if (dataType === "explorer") {
    requiredData = getRequiredExplorerData(data1);
  } else if (dataType === "bookings") {
    requiredData = getExportBookingsData(data1);
  } else if (dataType === "purchasedGiftCards") {
    requiredData = getRequiredPurchasedGiftCardData(data1);
  } else if (dataType === "redeemedGiftCards") {
    requiredData = getRequiredRedeemedGiftCardData(data1);
  }
  exportFile(requiredData, fileName, false);
};

const errorApiCall = (data: any, dataType: string) => {
  return;
};

const getFileName = (dataType: string) => {
  return `${dataType} report`;
};

function exportFile(
  data: any[],
  fileName = "Report",
  skipHeader = false,
  columnHeadings?: string[]
) {
  const headings = [columnHeadings];
  const wb = utils.book_new();
  const ws = utils.json_to_sheet([]);
  columnHeadings && utils.sheet_add_aoa(ws, headings);
  utils.sheet_add_json(ws, data, { origin: "A1", skipHeader: skipHeader });
  utils.book_append_sheet(wb, ws, "Report");
  writeFile(wb, `${fileName}.xlsx`);
}
