/** class tab api calls */
import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const fetchRedemtionController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_ALL_REDEEMED_GIFTCARDS_DATA,
    successHandler,
    errorHandler,
    params
  );
};

export const fetchPurchasedController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.FETCH_ALL_PURCHASED_GIFTCARDS_DATA,
    successHandler,
    errorHandler,
    params
  );
};
