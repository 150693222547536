import * as React from "react";
import { useNavigate } from "react-router-dom";

import * as components from "../../../components";
import { IMAGES } from "../../../assets/images";
import { columnRedeemHeaders, columnPurchasedHeaders } from "../../../utils/reusable-functions/giftcards/listing/listingTableHeaders";
import { getPurchasedList, getRedemtionList } from "../../../utils/reusable-functions/giftcards/listing/apiFuntions";
import useMessage from "../../../hooks/customHooks/useMessage";
import { GiftCardListingComponent } from "src/compoundComponents/GiftCardListingComponent";

const GiftCardListingPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { setMsg } = useMessage();

  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 20,
  });

  const [selectedFilters, setSelectedFilters] = React.useState();
  const [reloadData, setReloadData] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const [activeTable, setActiveTable] = React.useState<"redeem" | "purchased">("purchased");

  React.useEffect(() => {
    if (activeTable === "redeem") {
      getRedemtionList(setPageState, pageState, setMsg);
    } else if (activeTable === "purchased") {
      getPurchasedList(setPageState, pageState, setMsg);
    }
  }, [activeTable, pageState.page, pageState.pageSize, selectedFilters, searchText, reloadData]);

  return (
    <>
      {/* Buttons to toggle between tables */}
      <div style={{ display: "flex", justifyContent: "left", marginBottom: "20px" }}>
      <button
          style={{
            padding: "10px 20px",
            backgroundColor: activeTable === "purchased" ? "#2D2178" : "#ccc",
            color: "#fff",
            marginRight: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => setActiveTable("purchased")}
        >
          Purchased Data
        </button>
        <button
          style={{
            padding: "10px 20px",
            backgroundColor: activeTable === "redeem" ? "#2D2178" : "#ccc",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => setActiveTable("redeem")}
        >
          Redeem Data
        </button>
      </div>

      {/* Conditional rendering of tables */}
      {activeTable === "redeem" && (
        <GiftCardListingComponent
          // searchField={{
          //   placeholder: "Search Redeem Data",
          //   onSearchClick: (searchText1: string) => setSearchText(searchText1),
          // }}
          pageState={pageState}
          setPageState={setPageState}
          columnHeaders={columnRedeemHeaders()}
        />
      )}
      {activeTable === "purchased" && (
        <GiftCardListingComponent
          // searchField={{
          //   placeholder: "Search Purchased Data",
          //   onSearchClick: (searchText1: string) => setSearchText(searchText1),
          // }}
          pageState={pageState}
          setPageState={setPageState}
          columnHeaders={columnPurchasedHeaders()}
        />
      )}
    </>
  );
};

export default GiftCardListingPage;
